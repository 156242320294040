import { getImage } from 'gatsby-plugin-image'

import FaqSection from '~/components/blocks/FAQ'
import HeadSection from '~/components/blocks/HeadSection'
import CallToAction from '~/components/call-to-actions/CallToAction'
import Service3DIconCard from '~/components/cards/Service3DIconCard'
import ServicesIconSmallCard from '~/components/cards/ServicesIconSmallCard'
import ServicesIndustriesCard from '~/components/cards/ServicesIndustriesCard'
import ClientVideoQuotes from '~/components/items/ClientVideoQuotes'
import DiscoverWorksTabs from '~/components/items/DiscoverWorksTabs'
import MainLayout from '~/components/layouts/MainLayout'
import PrimeContactFormSection from '~/components/sections/PrimeContactFormSection'
import PrimeSectionServices from '~/components/sections/PrimeSectionServices'
import SubtractBlock from '~/components/shared/SubtractBlock'
import StickyNavigation from '~/components/sticky/StickyNavigation'
import Anchors from '~/mock/services/ecommerce-marketplace-development/Anchors'
import EcommerceMarketplaceDevBenefits from '~/mock/services/ecommerce-marketplace-development/EcommerceMarketplaceDevBenefits'
import EcommerceMarketplaceDevBusinesses from '~/mock/services/ecommerce-marketplace-development/EcommerceMarketplaceDevBusinesses'
import EcommerceMarketplaceDevExpertise from '~/mock/services/ecommerce-marketplace-development/EcommerceMarketplaceDevExpertise'
import EcommerceMarketplaceDevFAQ from '~/mock/services/ecommerce-marketplace-development/EcommerceMarketplaceDevFAQ'
import EcommerceMarketplaceDevFeatures from '~/mock/services/ecommerce-marketplace-development/EcommerceMarketplaceDevFeatures'
import EcommerceMarketplaceDevIndustries from '~/mock/services/ecommerce-marketplace-development/EcommerceMarketplaceDevIndustries'
import EcommerceMarketplaceDevProcess from '~/mock/services/ecommerce-marketplace-development/EcommerceMarketplaceDevProcess'
import EcommerceMarketplaceDevServices from '~/mock/services/ecommerce-marketplace-development/EcommerceMarketplaceDevServices'
import EcommerceMarketplaceDevSolutions from '~/mock/services/ecommerce-marketplace-development/EcommerceMarketplaceDevSolutions'
import EcommerceMarketplaceDevTabContent from '~/mock/services/ecommerce-marketplace-development/EcommerceMarketplaceDevTabContent'
import EcommerceMarketplaceDevTabList from '~/mock/services/ecommerce-marketplace-development/EcommerceMarketplaceDevTabList'
import { fileToImageLikeData } from '~/utils'
import ServicesAchievementsClutchSection from '~/views/Services/components/ServicesAchievementsClutchSection'
import ServicesDevExpertiseCompany from '~/views/Services/components/ServicesDevExpertiseCompany'
import ServicesProcess from '~/views/Services/components/ServicesProcess'
import ServicesTechStackSection from '~/views/Services/components/ServicesTechStackSection'
import useEcommerceMarketplaceStaticQuery from '~/views/Services/EcommerceMarketplace/useEcommerceMarketplaceStaticQuery'

import * as containerStyles from './EcommerceMarketplace.module.scss'

const EcommerceMarketplaceDevelopment = () => {
  const query = useEcommerceMarketplaceStaticQuery()
  const ecommerceMarketplaceTabContent = EcommerceMarketplaceDevTabContent()
  const clientPMK = getImage(fileToImageLikeData(query.clientPMK))

  return (
    <MainLayout scrollTop>
      <PrimeSectionServices
        titleTop="Ecommerce Marketplace"
        title="Development"
        description="Are you looking for a profitable project? The Ecommerce marketplace platform is distinguished by attracting a large audience, which is an important condition for running a successful business."
        breadcrumbSecondLevel="Services"
        breadcrumbSecondLevelPath="/services/"
        breadcrumbCurrent="Ecommerce Marketplace Development"
        breadcrumbCurrentPath="/services/ecommerce-marketplace-development/"
      />

      <StickyNavigation anchors={Anchors} />

      <section
        id="services"
        className={containerStyles.eMarketplaceDevServicesIconSection}
      >
        <HeadSection
          position="center"
          title="Ecommerce marketplace development services"
          description={
            <>
              Codica is about custom online marketplaces and SaaS products
              development. We already have more than 50 successful projects
              delivered by our 60+ specialists.
              <span className="mb-2 d-block" key="keySpanDescription" />
              Our specialists also develop high-quality Ecommerce platforms for
              customers worldwide. With ecommerce marketplace development
              services, our clients always get profitable and highly engaging
              products.
            </>
          }
          descriptionLarge
        />
        <Service3DIconCard dataContent={EcommerceMarketplaceDevServices} />
      </section>

      <section
        id="solutions"
        className={containerStyles.eMarketplaceDevCardBrdrIconSection}
      >
        <HeadSection
          position="center"
          title="Various formats of ecommerce marketplace development solutions"
          description="We are specialists in a broad marketplace profile. Our team can help create your B2B, B2C, or multi-vendor marketplace. Codica will deliver a fast-loading and scalable custom platform your users prefer."
          descriptionLarge
        />
        <ServicesIconSmallCard
          isBorder
          dataContent={EcommerceMarketplaceDevSolutions}
        />
        <SubtractBlock />
      </section>

      <CallToAction
        title="Are you ready to take your business to the next level?"
        subtitle="Let us help you create a thriving online marketplace."
        classNameForGA="servicesCTA_1"
        linkName="Talk to an expert"
        link="/contacts/"
      />

      <section
        id="features"
        className={containerStyles.eMarketplaceDevFeaturesSection}
      >
        <HeadSection
          position="center"
          title="Top must-have features we will deliver for your ecommerce platform"
          description="We advise our clients to carefully select the critical online marketplace features for buyers. Hence, the best features can attract new customers to your Ecommerce marketplace solution and help your business thrive."
          titleLarge
          descriptionLarge
        />
        <ServicesIconSmallCard
          isColSm
          isBorder
          dataContent={EcommerceMarketplaceDevFeatures}
        />
      </section>

      <section
        id="business-type"
        className={containerStyles.eMarketplaceDevBusinessSection}
      >
        <HeadSection
          position="center"
          title="Business types we serve"
          description="Our mission is to help clients create successful web products, regardless of the size of their business idea. At Codica, we have many years of experience creating effective Ecommerce marketplace solutions."
          descriptionLarge
        />
        <ServicesIndustriesCard
          dataContent={EcommerceMarketplaceDevBusinesses}
        />
      </section>

      <section
        className={containerStyles.eMarketplaceDevIndustriesSection}
        id="industries"
      >
        <HeadSection
          position="center"
          title="Domain expertise you can rely on"
          description="Our experience developing software for specific fields has enabled us to grow our in-house expertise, which we are open to sharing with our clients. We offer consultations on every phase of software development and make reliable and modern products."
          descriptionLarge
        />
        <ServicesIndustriesCard
          dataContent={EcommerceMarketplaceDevIndustries}
        />
      </section>

      <section
        id="process"
        className={containerStyles.eMarketplaceDevProcessSection}
      >
        <HeadSection
          position="center"
          title="Ecommerce marketplace development process"
          description="The Ecommerce marketplace development is a complex step-by-step process. Codica helps our clients at every development stage, from idea analysis, up to its post-launch support."
          descriptionLarge
        />
        <ServicesProcess dataContent={EcommerceMarketplaceDevProcess} />
      </section>

      <section className={containerStyles.eMarketplaceDevExpertiseSection}>
        <HeadSection
          position="center"
          title="Codica’s development experience by numbers"
        />
        <ServicesDevExpertiseCompany
          dataContent={EcommerceMarketplaceDevExpertise}
        />
      </section>

      <section
        id="tech-stack"
        className={containerStyles.eMarketplaceDevTechStackSection}
      >
        <HeadSection
          position="center"
          title="Best tech stack to build ecommerce marketplace"
          description="The need to keep up with the times dictates the steady development of technologies. Today your platform is convenient and fast, but tomorrow there may be a breakthrough in IT, and you will need updates. Therefore, it is crucial to predict the development of technologies to be on trend now and in the future. At Codica, our experts are eager to help you to choose the most advanced technologies to leave your marketplace competitors far behind."
          descriptionLarge
        />
        <ServicesTechStackSection />
      </section>

      <section
        id="benefits"
        className={containerStyles.eMarketplaceDevBenefitsSection}
      >
        <HeadSection
          position="center"
          title="Benefits of the ecommerce marketplace development with Codica"
          description="So, how does Codica stand out in a competition of Ecommerce marketplace website development? We can solve your business development challenges with our skilled team and best technology practices."
          titleLarge
          descriptionLarge
        />
        <ServicesIconSmallCard dataContent={EcommerceMarketplaceDevBenefits} />
      </section>

      <CallToAction
        title="Ready to take your business online and reach a wider audience?"
        subtitle="Let's build your own ecommerce marketplace."
        classNameForGA="servicesCTA_2"
        linkName="Contact us"
        link="/contacts/"
      />

      <section
        id="cases"
        className={containerStyles.eMarketplaceDevCaseSection}
      >
        <HeadSection position="left" title="Our case studies" />
        <DiscoverWorksTabs
          dataTabList={EcommerceMarketplaceDevTabList}
          dataContent={ecommerceMarketplaceTabContent}
        />
      </section>

      <section
        className={containerStyles.eMarketplaceDevOurAchievementsSection}
      >
        <HeadSection
          position="center"
          title="Codica’s expertise in marketplace development"
          description="For more than eight years, we have been creating innovative software products to develop our clients' businesses in various industries and scales. Thanks to the highest quality services provided to our clients, Codica was already listed among Top Developers by Clutch in 2019-2023."
          descriptionLarge
        />
        <ServicesAchievementsClutchSection />
      </section>

      <section className={containerStyles.eMarketplaceDevClientQuotesSection}>
        <HeadSection
          position="center"
          title="Our happy clients"
          description="At Codica, we take pride in delivering top-notch software solutions that exceed our clients’ expectations and help them achieve their business goals. Our clients come from a wide range of backgrounds, but they all have one thing in common - their satisfaction with our services."
          descriptionLarge
        />
        <div className="container container-md">
          <ClientVideoQuotes
            name="David Watkins"
            location="USA"
            position="Founder of PlanMyKids"
            image={clientPMK}
            quote={[
              {
                text: '“The PlanMyKids journey started when my family struggled to plan our own kid’s activities. As busy parents, we were time-constrained, the information we needed was all over the place, and coordinating with family felt like it required a project management certification.”',
              },
            ]}
            link="kids-activities-service-marketplace"
            videoLink="https://www.youtube.com/embed/dzxMCDLghZQ"
          />
        </div>
      </section>

      <section id="free-quote">
        <PrimeContactFormSection
          title="Let’s develop a unique ecommerce marketplace together!"
          widthTitle="850px"
          formInfoClassName="formInfo__services"
          customSalesInfo
          customSalesInfoStepFour="4. Our custom marketplace development experts will analyze your requirements and suggest the best ways to bring your idea to life."
        />
      </section>

      <section id="faq">
        <FaqSection title="FAQ" dataContent={EcommerceMarketplaceDevFAQ} />
      </section>
    </MainLayout>
  )
}

export default EcommerceMarketplaceDevelopment
