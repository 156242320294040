import { graphql, useStaticQuery } from 'gatsby'

import { PhotosQuery } from '~/types/graphql'

const useServicesAchievementsClutchStaticQuery = () => {
  const query = useStaticQuery<PhotosQuery>(graphql`
    query {
      iconClutchDev2020: file(
        relativePath: { eq: "awards/icon-clutch-2020.png" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      iconClutchDev2021: file(
        relativePath: { eq: "awards/icon-clutch-2021.png" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      iconClutchDev2023: file(
        relativePath: { eq: "awards/icon-clutch-dev-2023.png" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      iconClutchGlobal: file(
        relativePath: { eq: "awards/icon-clutch-global.png" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      iconClutchChampion: file(
        relativePath: { eq: "awards/icon-clutch-champion.png" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }
    }
  `)

  return query
}

export default useServicesAchievementsClutchStaticQuery
