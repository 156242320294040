import { ExpertiseSectionMock } from '~/types/mock'

const EcommerceMarketplaceDevServices: ExpertiseSectionMock[] = [
  {
    icon: 'sprite3DIcon_10',
    title: 'Consulting services',
    description:
      'We thoroughly study what average customers would need, what problems they might have, and how the marketplace can help to solve them.',
  },
  {
    icon: 'sprite3DIcon_12',
    title: 'UI/UX design services',
    link: '/services/ui-ux-design/',
    description:
      'Our UI/UX experts are always keeping up with new design tendencies to make your marketplace trendy and easy to use.',
  },
  {
    icon: 'sprite3DIcon_13',
    title: 'Ecommerce marketplace development',
    description:
      'We meet the unique marketplace requirements with front-end/back-end development, database and payment gateway integration, and customization.',
  },
  {
    icon: 'sprite3DIcon_19',
    title: 'QA testing services',
    link: '/services/quality-assurance/',
    description:
      'Our QA specialists perform various testing procedures to ensure the smooth operation of your business idea.',
  },
  {
    icon: 'sprite3DIcon_20',
    title: 'Support and maintenance',
    description:
      'We provide regular updates, fix issues, and perform optimizations to ensure your marketplace stays secure, stable, and up-to-date.',
  },
]

export default EcommerceMarketplaceDevServices
