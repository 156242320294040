import { ExpertiseSectionMock } from '~/types/mock'

const EcommerceMarketplaceDevBusinesses: ExpertiseSectionMock[] = [
  {
    icon: 'sprite3DIconSmall_7',
    title: 'Startups',
    description:
      'We can help startups develop a prototype, create a new MVP, or scale an existing one fast and efficiently. Therefore, we launch products and test ideas quickly and within the budget to deliver intuitive and convenient functionality.',
  },
  {
    icon: 'sprite3DIconSmall_8',
    title: 'Small & mid-scale businesses',
    description:
      'With Codica’s Ecommerce marketplace development services, our specialists will help make your business platform highly responsive and visually appealing. Moreover, you can rely on our expert support after the launch of your solution.',
  },
  {
    icon: 'sprite3DIconSmall_9',
    title: 'Enterprises',
    description:
      'When you already have an Ecommerce marketplace software solution, you may need help with maintenance and support. We are ready to help you effectively cope with any challenges, including the automation of business processes and cost savings.',
  },
]

export default EcommerceMarketplaceDevBusinesses
