import { Link } from 'gatsby'

import { ExpertiseSectionMock } from '~/types/mock'

const EcommerceMarketplaceDevBenefits: ExpertiseSectionMock[] = [
  {
    icon: 'spriteIcon_27',
    title: 'SEO-friendly',
    description:
      'We develop Ecommerce solutions per SEO requirements, allowing customers to increase positions in the results of search engines.',
  },
  {
    icon: 'spriteIcon_63',
    title: 'User-friendly',
    description:
      'Our clients receive attractive, intuitive, and easy-to-navigate finished marketplaces. These platforms guarantee convenient interaction for both sellers and buyers.',
  },
  {
    icon: 'spriteIcon_101',
    title: 'Safety',
    description: (
      <>
        Protecting confidential data is an essential aspect of our
        <Link
          to="/services/online-marketplace-development/"
          className="mx5"
          key="keySafetyLink"
        >
          online marketplace development services.
        </Link>
        We use built-in security tools to make your platforms resilient to
        threats.
      </>
    ),
  },
  {
    icon: 'spriteIcon_16',
    title: 'Engaging',
    description:
      'The Codica team develops robust and scalable solutions. We help clients cope with the growing user number and increased platform workload.',
  },
  {
    icon: 'spriteIcon_88',
    title: 'Speed',
    description:
      'We are passionate about the best user experience. We use the top-notch technologies for high loading speed and overall marketplace performance.',
  },
  {
    icon: 'spriteIcon_14',
    title: 'Cost-effectiveness',
    description:
      'Our experts offer a cost-effective model that suits your project best. We make perfect marketplaces for any business under budget.',
  },
]

export default EcommerceMarketplaceDevBenefits
