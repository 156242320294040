import { FAQ } from '~/types/data-array'

const EcommerceMarketplaceDevFAQ: FAQ[] = [
  {
    question:
      'How long does it take to develop an Ecommerce marketplace platform?',
    answer:
      'The timeframe of Ecommerce website development relies on many factors, especially the website complexity and the development team. Generally, basic Ecommerce website development time is 3-5 months; medium ecommerce websites are 6-9 months; complex Ecommerce websites take up to 9 months.',
  },
  {
    question:
      'How much does it cost to develop a custom Ecommerce marketplace?',
    answer: `On average, the [cost to build a marketplace](/blog/how-much-does-it-cost-to-build-marketplace-website/) will start from $68,400. However, it is challenging to estimate required development costs without project details, including the complexity of design and features.`,
  },
  {
    question: 'What industries rely on Ecommerce marketplace development?',
    answer:
      'Nowadays, there are many trending industries for Ecommerce marketplace development. One of the most booming industries is the consumer electronics and gadgets sector. Also, we can mention e-learning, retail, grocery, food delivery, and rental niches.',
  },
]

export default EcommerceMarketplaceDevFAQ
