import { HomeSpecification } from '~/types/mock'

const EcommerceMarketplaceDevIndustries: HomeSpecification[] = [
  {
    icon: 'sprite3DIconSmall_10',
    title: 'Online marketplaces',
    description: 'Empowered with enhanced user experiences.',
    link: '/case-studies/kids-activities-service-marketplace/',
  },
  {
    icon: 'sprite3DIconSmall_13',
    title: 'eCommerce',
    description: 'Captivating eCommerce marketplaces that drive conversions.',
    link: '/case-studies/custom-multi-vendor-marketplace-platform/',
  },
  {
    icon: 'sprite3DIconSmall_14',
    title: 'Travel',
    description: 'Visually appealing and seamless online travel solutions.',
    link: '/case-studies/travel-management-platform/',
  },
  {
    icon: 'sprite3DIconSmall_15',
    title: 'Automotive',
    description: 'Efficient apps for the vehicle sale and rental business.',
    link: '/services/automotive-software-development/',
  },
  {
    icon: 'sprite3DIconSmall_4',
    title: 'Insurance',
    description: 'Reliable and secure insurance marketplace apps.',
    link: '/services/insurance-software-development/',
  },
  {
    icon: 'sprite3DIconSmall_11',
    title: 'Recruiting',
    description:
      'Top marketplace software that enhances the recruitment experience.',
    link: '/case-studies/recruitment-progressive-web-application/',
  },
  {
    icon: 'sprite3DIconSmall_16',
    title: 'Rental',
    description: 'Quality rental marketplace development to win the market.',
    link: '/case-studies/accommodation-search-website-mvp/',
  },
  {
    icon: 'sprite3DIconSmall_17',
    title: 'Media',
    description: 'Visually appealing media marketplaces that attract users.',
    link: '/case-studies/news-aggregator/',
  },
  {
    icon: 'sprite3DIconSmall_18',
    title: 'Healthcare',
    description:
      'Innovative healthcare solutions that bring profit and enhance patient care.',
    link: '/case-studies/fitness-progressive-web-application/',
  },
]

export default EcommerceMarketplaceDevIndustries
