export interface EcommerceServicesIconTitle {
  icon: string
  title: string
}

const EcommerceMarketplaceDevFeatures: EcommerceServicesIconTitle[] = [
  {
    icon: 'spriteIcon_87',
    title: 'Sign in/sign up',
  },
  {
    icon: 'spriteIcon_51',
    title: 'Dispute resolution system',
  },
  {
    icon: 'spriteIcon_100',
    title: 'Push notifications',
  },
  {
    icon: 'spriteIcon_57',
    title: 'Registration through social networks',
  },
  {
    icon: 'spriteIcon_50',
    title: 'Admin analytics and data dashboards',
  },
  {
    icon: 'spriteIcon_37',
    title: 'User profile and private data management',
  },
  {
    icon: 'spriteIcon_32',
    title: 'Search functionality and filtering options',
  },
  {
    icon: 'spriteIcon_44',
    title: 'Wide range of currencies and languages',
  },
  {
    icon: 'spriteIcon_29',
    title: 'Reservation/order process for clients',
  },
  {
    icon: 'spriteIcon_2',
    title: 'Smooth, attractive interface',
  },
  {
    icon: 'spriteIcon_17',
    title: 'Quick re-order function',
  },
  {
    icon: 'spriteIcon_23',
    title: 'Landing pages for buyers/sellers',
  },
  {
    icon: 'spriteIcon_47',
    title: 'Feedback and rating system',
  },
  {
    icon: 'spriteIcon_42',
    title: 'Regular inventory updates',
  },
  {
    icon: 'spriteIcon_96',
    title: 'Shipping and tracking system',
  },
  {
    icon: 'spriteIcon_97',
    title: 'Payment methods',
  },
  {
    icon: 'spriteIcon_34',
    title: 'Affiliate and referral programs',
  },
  {
    icon: 'spriteIcon_33',
    title: 'Commission control',
  },
  {
    icon: 'spriteIcon_61',
    title: 'Client service features',
  },
  {
    icon: 'spriteIcon_98',
    title: 'Chat management system',
  },
  {
    icon: 'spriteIcon_21',
    title: 'Seller-specific dashboards',
  },
  {
    icon: 'spriteIcon_12',
    title: 'Listing management',
  },
]

export default EcommerceMarketplaceDevFeatures
