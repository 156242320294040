// extracted by mini-css-extract-plugin
export var eMarketplaceDevBenefitsSection = "G_gb";
export var eMarketplaceDevBusinessSection = "G_f5";
export var eMarketplaceDevCardBrdrIconSection = "G_f3";
export var eMarketplaceDevCaseSection = "G_gc";
export var eMarketplaceDevClientQuotesSection = "G_gf";
export var eMarketplaceDevExpertiseSection = "G_f8";
export var eMarketplaceDevFeaturesSection = "G_f4";
export var eMarketplaceDevIndustriesSection = "G_f6";
export var eMarketplaceDevOurAchievementsSection = "G_gd";
export var eMarketplaceDevProcessSection = "G_f7";
export var eMarketplaceDevServicesIconSection = "G_f2";
export var eMarketplaceDevTechStackSection = "G_f9";