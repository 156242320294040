import { Link } from 'gatsby'

import { ServicesProcessMock } from '~/types/mock'

const EcommerceMarketplaceDevProcess: ServicesProcessMock[] = [
  {
    num: '01',
    title: [
      <Link to="/services/product-discovery/" key="keyTitleProductDiscovery">
        Product discovery
      </Link>,
    ],
    descr:
      'We can explore the subject area, identify bottlenecks, and develop prototypes. Also, our experts create the roadmap and provide project cost and time estimates.',
  },
  {
    num: '02',
    title: 'UI/UX design',
    descr:
      'Our skillful designers deliver attractive and user-friendly custom software products. We use lovely color palettes, trendy fonts, intuitive icons, and a straightforward interface.',
  },
  {
    num: '03',
    title: 'Product development',
    descr:
      'We create the MVP based on the latest industry standards. With Ruby on Rails, React, VueJS, and other technologies, we quickly develop secure and quality solutions.',
  },
  {
    num: '04',
    title: 'Optimization and QA',
    descr:
      'Our QA engineers consider their top priority in quality control and software testing for the best results for the final product. We do our best to ensure everything works correctly.',
  },
  {
    num: '05',
    title: 'Deployment and support',
    descr:
      'With our maintenance and support, you get bug fixes, backup, monitoring, redesigning, improvement, and updating of systems, and constant support.',
  },
]

export default EcommerceMarketplaceDevProcess
