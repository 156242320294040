import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image'

import { cx, fileToImageLikeData } from '~/utils'

import * as containerStyles from './ServicesAchievementsClutchSection.module.scss'
import useServicesAchievementsClutchStaticQuery from './useServicesAchievementsClutchStaticQuery'

const ServicesAchievementsClutchSection = () => {
  const query = useServicesAchievementsClutchStaticQuery()

  const iconClutchDev2020 = getImage(
    fileToImageLikeData(query.iconClutchDev2020),
  )
  const iconClutchDev2021 = getImage(
    fileToImageLikeData(query.iconClutchDev2021),
  )
  const iconClutchDev2023 = getImage(
    fileToImageLikeData(query.iconClutchDev2023),
  )
  const iconClutchGlobal = getImage(fileToImageLikeData(query.iconClutchGlobal))
  const iconClutchChampion = getImage(
    fileToImageLikeData(query.iconClutchChampion),
  )

  const clutchAwards = [
    {
      logo: iconClutchDev2020,
      title: 'Top E-Commerce Developers in Ukraine 2020',
    },
    {
      logo: iconClutchDev2021,
      title: 'Top Developers in Ukraine 2021',
    },
    {
      logo: iconClutchDev2023,
      title: 'Top Ruby On Rails Developer in Kyiv 2023',
    },
    {
      logo: iconClutchGlobal,
      title: 'Clutch Global 2023',
    },
    {
      logo: iconClutchChampion,
      title: 'Clutch Champion 2023',
    },
  ]

  return (
    <div className="container container-md">
      <div className={cx('row', containerStyles.servicesClutchSection)}>
        {clutchAwards.map(({ logo, title }) => (
          <div className="col-6 col-sm-4 col-lg-2" key={title}>
            <div className={containerStyles.servicesClutchSection__logo}>
              <GatsbyImage
                image={logo as IGatsbyImageData}
                alt={`${title} | Codica`}
                title={title}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default ServicesAchievementsClutchSection
