export interface ServicesCardWithTopBorderData {
  title: string
  description: string
  link?: string
}

const EcommerceMarketplaceDevSolutions: ServicesCardWithTopBorderData[] = [
  {
    title: 'B2B marketplace',
    link: '/blog/how-to-create-b2b-marketplace/',
    description:
      'Provide a solution for business. Trade to prominent players. Be the first in your domain.',
  },
  {
    title: 'B2C marketplace',
    link: '/blog/build-b2c-marketplace/',
    description:
      'Offer multiple goods or services. Provide the brands. Make conditions for competition.',
  },
  {
    title: 'C2C marketplace',
    link: '/blog/build-c2c-marketplace/',
    description:
      'Offer goods or services. Present own UVP. Build a community of consumers.',
  },
  {
    title: 'P2P marketplace',
    description:
      'Intended for shoppers and sellers, where sides can switch roles. Overcome the chicken and egg problem.',
  },
  {
    title: 'Multi-vendor marketplace development',
    description:
      'Allow vendors and manufacturers to connect with many customers more simply and satisfy their requirements.',
  },
]

export default EcommerceMarketplaceDevSolutions
