import { DataTabList } from '~/types/data-array'

const EcommerceMarketplaceDevTabList: DataTabList[] = [
  {
    name: 'RVs marketplace',
  },
  {
    name: 'Ecommerce solution',
  },
  {
    name: 'Boating portal',
  },
]

export default EcommerceMarketplaceDevTabList
